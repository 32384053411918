<template>
    <div>
        <div class="firm-panel">
            <h3 class="g-title">客户评价<span>（{{total}}条）</span></h3>
            <div
                class="evaluate-panel"
                v-if="total > 0"
            >
                <ul class="evaluate-items">
                    <li
                        class="item"
                        v-for="(item,index) in evaluate"
                        :key="index"
                    >
                        <div class="avatar">
                            <img :src="item.avatar?websiteConfigs.sourceUrl +item.avatar:$img.firm_avatar">
                        </div>
                        <div class="evaluate-cont">
                            <div class="hd">
                                <time class="date">{{item.create_time}}</time>
                                <h4 class="title">{{item.employer_name}}</h4>
                            </div>
                            <div class="project-name">{{item.task_name}}</div>
                            <div class="evaluate-txt">{{item.content}}<a
                                    href="javascript:void(0)"
                                    class="more"
                                >显示全部</a></div>
                        </div>
                    </li>
                </ul>
                <pagination></pagination>
            </div>
            <div
                class="evaluate-panel"
                v-if="total == 0"
            >
                <p style="width:100%;text-align:center;height:300px;line-height:300px;font-size:14px;color:#999;">暂无数据</p>
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from "@/components/common/Pagination.vue";
export default {
    name: "evaluate",
    components: {
        Pagination //分页
    },
    data() {
        return {
            page: 1,
            limit: 10,
            total: 0,
            evaluate: []
        };
    },
    mounted() {
        this.loadListData(this.$attrs["data-id"]);
    },
    methods: {
        loadListData(user_id) {
            var _this = this;
            _this.post(
                "/user/user/comment",
                {
                    user_id: user_id,
                    page: _this.page,
                    limit: _this.limit
                },
                function (res) {
                    if (res.code == 200) {
                        _this.total = res.data.total;
                        _this.evaluate = res.data.list;
                    }
                }
            );
        },
        showTask(item) {
            var _this = this;
            _this.task = item;
            _this.terraceDialog = true;
        }
    }
};
</script>





